<template>
  <div></div>
</template>

<script>
import {destroyToken, destroyUser} from "@/core/services/jwt.service";
import {updateMe} from "@/core/services/utils.service";

export default {
  name: "Logout",
  mounted() {
    destroyToken();
    destroyUser();
    updateMe("logout");
    this.$router.push({name: "Login"});
  }
}
</script>
